const PAGING_PAGES = 3
const NULL_FORM_VALUE = ""

Vue.component('tours-search-form', Vue.extend({
  name: 'ToursSearchForm',
  data: function () {
    return {
      tours: [],
      filtered_tours: [],
      country_id: NULL_FORM_VALUE,
      complexity_id: NULL_FORM_VALUE,
      type_id: NULL_FORM_VALUE,
      with_pagination: true,
      per_page: 8,
      current_page: 1,
      visible_min: 1,
      visible_max: PAGING_PAGES,
      total_pages: 0,
    }
  },

  created: function() {
    this.tours = window.init_data[this.$attrs['init-var-name']]

    if (this.$attrs['with-pagination'] === 'false') {
      this.with_pagination = false
    }

    if (this.with_pagination) {
      this.calculate_total_pages(this.tours)
      this.filtered_tours = _.first(this.tours, this.per_page)
    } else {
      this.filtered_tours = this.tours
    }

    this.$on('search:filter_country', (country_id) => {
      this.filter_country(country_id)
    })

    this.$on('search:filter_type', (type_id) => {
      this.filter_type(type_id)
    })

    this.$on('search:filter_complexity', (complexity_id) => {
      this.filter_complexity(complexity_id)
    })

    this.$on('search:reset', () => {
      this.reset_search()
    })

    this.$on('search:paginate', (page) => {
      this.current_page = parseInt(page)
      this.visible_min  = this.current_page == 1 ? 1 : this.current_page - 1
      this.visible_max  = this.current_page == 1 ? 3 : this.current_page + 1

      Vue.nextTick(() => {
        // TODO: FIXME: не работает
        var top = $(this.$el).offset().top
        $('body').scrollTop(top)
      })
    })
  },

  updated: function() {
    this.match_height()
  },

  mounted: function() {
    this.match_height()
  },

  methods: {
    match_height: function() {
      Vue.nextTick(() => {
        // $(this.$el).find('.Tour img.logo').matchHeight()
        $(this.$el).find('.Tour .title').matchHeight()
        $(this.$el).find('.Tour .center-container').matchHeight()
      })
    },
    calculate_total_pages: function(records) {
      var total = parseInt(_.size(records) / this.per_page)
      this.total_pages = total < 1 ? 1 : total
    },
    filter: function () {
      var new_tours_list = this.tours

      if (this.country_id && this.country_id > 0) {
        new_tours_list = _.filter(new_tours_list, (tour) => _.contains(tour.country_ids, parseInt(this.country_id)))
      }

      if (this.complexity_id && this.complexity_id > 0) {
        new_tours_list = _.filter(new_tours_list, (tour) => _.contains(tour.complexity_ids, parseInt(this.complexity_id)))
      }

      if (this.type_id && this.type_id > 0) {
        new_tours_list = _.filter(new_tours_list, (tour) => _.contains(tour.type_ids, parseInt(this.type_id)))
      }

      if (this.with_pagination) {
        var a = (this.current_page - 1) * this.per_page
        var b = a + this.per_page

        this.calculate_total_pages(new_tours_list)
        this.filtered_tours = new_tours_list.slice(a, b)
      } else {
        this.filtered_tours = new_tours_list
      }

      this.scroll_to_begin()
    },

    filter_type: function (type_id) {
      this.country_id    = NULL_FORM_VALUE
      this.type_id       = type_id
      this.complexity_id = NULL_FORM_VALUE
      this.rewind_paging()
    },

    filter_complexity: function (complexity_id) {
      this.country_id    = NULL_FORM_VALUE
      this.type_id       = NULL_FORM_VALUE
      this.complexity_id = complexity_id
      this.rewind_paging()
    },

    filter_country: function (country_id) {
      this.country_id    = country_id
      this.type_id       = NULL_FORM_VALUE
      this.complexity_id = NULL_FORM_VALUE
      this.rewind_paging()
    },

    reset_paging: function() {
      this.current_page   = 1
      this.total_pages    = 0
      // this.filtered_tours = []
      this.visible_min    = 0
      this.visible_max    = PAGING_PAGES
    },

    rewind_paging: function() {
      this.current_page   = 1
      this.visible_min    = 0
      this.visible_max    = PAGING_PAGES
    },

    reset_search: function () {
      this.country_id     = NULL_FORM_VALUE
      this.type_id        = NULL_FORM_VALUE
      this.complexity_id  = NULL_FORM_VALUE

      this.reset_paging()
      this.filter()

      Vue.nextTick(() => {
        this.scroll_to_begin()
      })
    },

    scroll_to_begin: function ($event) {
      if ($event) { $event.preventDefault() }

      // const top = $('#sticky-wrapper').offset().top
      // $('body').scrollTop(top)
    },

    load_more: function() {
      this.per_page = this.per_page + 8
      this.filter()
    }

  },

  watch: {
    country_id:    function () { this.rewind_paging(); this.filter() },
    type_id:       function () { this.rewind_paging(); this.filter() },
    complexity_id: function () { this.rewind_paging(); this.filter() },
    current_page:  function () { this.filter() }
  }

}))
