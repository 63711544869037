Vue.component('velo-tour', Vue.extend({
  name: 'VeloTour',
  data: function () {
    return {
    }
  },

  created: function() {
    // if (window.open_order_form) {}

    if (window.location.hash == '#tour-request') {
      var top_x = $('.tour-order-container').offset().top
      $('body').scrollTop(top_x)
    }
  },
}))
