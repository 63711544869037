const _ = require('underscore')
window._ = _

const $ = require('jquery')
window.$ = $
window.jQuery = $

require('jquery-ujs')

require('select2/dist/js/select2.js')
require('select2/dist/js/i18n/ru')

require('../../../vendor/assets/javascripts/fancybox')

require('pickadate/lib/picker')
require('pickadate/lib/picker.date')
require('pickadate/lib/picker.time')
require('pickadate/lib/translations/ru_RU')

const Swiper = require('swiper/dist/js/swiper')

require('jquery-match-height/jquery.matchHeight')
require('jquery.sticky/dist/jquery.sticky')
require('jquery.scrollto/jquery.scrollTo')
require('jquery.scrollup/dist/jquery.scrollUp')

// const Vue = require('vue/dist/vue.runtime.common.js') // runtime only
const Vue = require('vue/dist/vue.common.js') // full
window.Vue = Vue

const Cleave = require('cleave.js')
require('cleave.js/src/addons/phone-type-formatter.ru.js')

require('./application/mixins/remote_form')
require('./application/components/tour_remote_form')
require('./application/components/tours_list')
require('./application/components/tours_cards')
require('./application/components/tours_search_form')
require('./application/components/our_benefits')
require('./application/components/velo_tour')

const ContactsForm = require('./application/components/contacts_form.vue')

const VK = require('vk-openapi')

$('document').ready(function () {
  loadVue()
  loadMainMenuUi()
  try {
    loadTourRouteUi()
  } catch(e) {
    console.log('Failed ->', e)
  }
  loadFaqQuestionsUi()
  loadContentTypography()
  loadUploadPicturesSwiper()
  loadVKGroups()
  // loadGoogleSearch()
  // loadUserVoice()

  // match block height
  $('.what-is-velotour-comprasion-container .compare-element-container').matchHeight()
  $('.main-tour-types-container .compare-element-container').matchHeight()
  $('.main-tour-types-container .top-line').matchHeight()
  $('.NewPromotedTours .information-container').matchHeight()
  $('.NewPromotedTours .information-container .title-url').matchHeight()
  $('.CompanyMembers .members').first().find('.member').matchHeight()

  // stick menus
  // don't stick on small screen sizes
  if ($('.layout-container').width() > 1000) {
    // $('.main-menu-container').sticky({getWidthFrom: ".layout-container", responsiveWidth: true});
    $('.tabs-container').sticky()

    $('.reviews-page-container .countries-menu').sticky()

    $('.ToursSearchForm').sticky()

    window.sticky_main_menu == true && $('.main-menu-container').sticky()
  }

  // fancybox
  $('.fancybox').fancybox()

  // pickAdate
  $('.order-pickdate').pickadate({
    format: 'yyyy-mm-dd',
    formatSubmit: undefined,
    hiddenPrefix: '',
    hiddenSuffix: '',
    selectMonths: true,
    selectYears: true,
    min: true,
    max: 730
  })

  $('.order-pickdate').on('mousedown', function (event) {
    event.preventDefault()
  })

  // select2
  $('.select2').select2({
    width: 'element'
  })

  // scrollup
  // $.scrollUp({
  //   scrollText: window.scroll_to_top_text
  // })

  // beaty scroll triggers
  const default_scroll_options = {
    duration: 1000
  }

  $('.header-container .main-target').click(function () {
    $(window).scrollTo($('.what-is-velotour-description-container'), default_scroll_options)
  })

  // Contacts Floating button
  $('.Contacts .FloatButton').click(function() {
    $(this).toggleClass('active')
    $('.Contacts .Widget').toggleClass('active')
  })

  $('.Contacts .close').click(function() {
    $('.Contacts .FloatButton').removeClass('active')
    $('.Contacts .Widget').removeClass('active')
  })

  // get right layout x
  var layout = $('.layout-container')
  var floatButton = $('.Contacts .FloatButton')
  var floatContacts = $('.Contacts .Widget')

  var btn_x = layout.offset().left + layout.width()
  btn_x = btn_x - floatButton.width() - (21.8833 * 5)

  var wgt_x = layout.offset().left + layout.width()
  wgt_x = wgt_x - floatContacts.width() - 21.8833 - (21.8833)

  floatButton.css({ 'left': btn_x + 'px', 'right': 'unset' })
  floatContacts.css({ 'left': wgt_x + 'px', 'right': 'unset' })
})

function loadMainMenuUi () {
  // lol, greatest way ever =P
  const is_mobile = ($('.layout-container').width() < 1024)

  // $('.main-menu-container').on('mouseenter', '.sub-menu-wrapper', function() {
  //   if (is_mobile($(this))) { return }

  //   var sub_menu = $(this).find('.sub-menu')
  //   var menu     = $(this).parents('.main-menu-container')

  //   sub_menu.addClass('active')

  // }).on('mouseleave', '.sub-menu-wrapper', function() {
  //   if (is_mobile($(this))) { return }

  //   var sub_menu = $(this).find('.sub-menu')
  //   var menu     = $(this).parents('.main-menu-container')

  //   sub_menu.removeClass('active')
  // })

  const close_all = function () {
    const parent = $('.main-menu-container')
    parent.find('.item').each(function (i, e) { $(e).removeClass('active') })
    parent.find('.sub-menu').each(function (i, e) { $(e).removeClass('active') })
    parent.find('.expand-on-mobile-button').each(function (i, e) { $(e).removeClass('active') })
    parent.find('.menu-content').each(function (i, e) { $(e).removeClass('active') })
    parent.find('.sub-menu').each(function (i, e) { $(e).removeClass('active') })
    parent.find('.mobile-content-placeholder').each(function (i, e) { $(e).html('') })
  }

  const close = function ($event) {
    // if (is_mobile) return;

    const parent = $('.main-menu-container')
    const current = $($event.currentTarget)
    const sub_menu = current.find('.sub-menu')
    const arrow = current.find('.expand-on-mobile-button')
    const item = current.find('.item')

    // var is_active = item.hasClass('active')

    // if (is_active == false && is_mobile == false) {
    // current.find('.menu-content').removeClass('active')
    // current.find('.item').removeClass('active')
    // current.find('.sub-menu').removeClass('active')
    // }

    item.removeClass('active')
    sub_menu.removeClass('active')
    arrow.removeClass('active')
    current.find('.menu-content').removeClass('active')
    current.find('.expand-on-mobile-button').removeClass('active')
    current.find('.mobile-content-placeholder').html('')
  }

  const open = function ($event) {
    close_all()
    // if (is_mobile) return;
    //

    // if (!$($event.target).attr('href')) {
    //     $event.preventDefault()
    // } else {
    //     return
    // }

    const current = $($event.currentTarget)

    const sub_menu = current.find('.sub-menu')
    const arrow = current.find('.expand-on-mobile-button')
    const item = current.find('.item')
    const parent = $('.main-menu-container')
    const menu = parent

    // var is_active = item.hasClass('active')
    // if (is_active == true && is_mobile == true) {
    //     close()
    //     return
    // }

    // close all

    const new_submenu_content_id = current.data().id
    if (new_submenu_content_id) {
      const new_menu_content = menu.find('.menu-content[data-id=' + new_submenu_content_id + ']')

      if (is_mobile) {
        const append_to = current.find('.mobile-content-placeholder')
        if (append_to.find('.sub-menu').length > 0) {
          append_to.html('')
        } else {
          append_to.html(new_menu_content.html())
        }
      } else {
        new_menu_content.addClass('active')
      }
    }

    item.addClass('active')
    sub_menu.addClass('active')
    arrow.addClass('active')
  }

  if (is_mobile) {
    $('.main-menu-container').on('click', '.sub-menu-wrapper', function ($event) {
      if (!$($event.target).attr('href')) {
        $event.preventDefault()
      } else {
        return
      }

      const is_active = $($event.currentTarget).find('.item').hasClass('active')

      if (is_active) {
        close($event)
      } else {
        open($event)
      }
    })
  } else {
    $('.main-menu-container').on('mouseenter', '.item', close)
    $('.main-menu-container').on('mouseenter', '.sub-menu-wrapper', open)
    $('.main-menu-container').on('mouseleave', close)

    $('.main-menu-container').on('mouseenter', 'a.item', close_all)
  }

  /// //////////////
  /// //////////////
  /// //////////////

  // $('.main-menu-container').on('click', '.sub-menu-wrapper', function($event) {
  //     // if (is_mobile($(this)) == false) return;

  //     if (!$($event.target).attr('href')) {
  //         $event.preventDefault()
  //     } else {
  //         return
  //     }

  //     var sub_menu = $(this).find('.sub-menu')
  //     var arrow = $(this).find('.expand-on-mobile-button')
  //     var item = $(this).find('.item')
  //     var menu = $(this).parents('.main-menu-container')

  //     var is_active = item.hasClass('active')

  //     if (is_active == false && is_mobile == false) {
  //         menu.find('.menu-content').removeClass('active')
  //         menu.find('.item').removeClass('active')
  //         menu.find('.sub-menu').removeClass('active')
  //         menu.find('.expand-on-mobile-button').removeClass('active')
  //     }

  //     // close all

  //     var new_submenu_content_id = $(this).data().id
  //     if (new_submenu_content_id) {
  //         var new_menu_content = menu.find('.menu-content[data-id=' + new_submenu_content_id + ']')

  //         if (is_mobile) {
  //             console.log('is-mobile')
  //             var append_to = $(this).find('.mobile-content-placeholder')
  //             if (append_to.find('.sub-menu').length > 0) {
  //                 append_to.html("")
  //             } else {
  //                 append_to.html(new_menu_content.html())
  //             }
  //         } else {
  //             new_menu_content.toggleClass('active')
  //         }
  //     }

  //     item.toggleClass('active')
  //     sub_menu.toggleClass('active')
  //     arrow.toggleClass('active')
  // })
} // loadMainMenuUi

function loadContentTypography () {
  $('.tour-container .tab-content img').addClass('pure-img')
} // loadContentTypography

function loadUploadPicturesSwiper () {
  new Swiper('.RussianTours .swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 4000
    },
    slidesPerView: 6,
    grabCursor: true,
    freeMode: true
  })

  new Swiper('.upload-images-container .swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 4000
    },
    slidesPerView: 'auto',
    grabCursor: true,
    freeMode: true
  })

  new Swiper('.header-container .swiper-container', {
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    autoplay: {
      delay: 3000
    },
    effect: 'fade'
  })
}

function loadFaqQuestionsUi () {
  const open_question_from_location_hash = function () {
    const hash = window.location.hash

    if (hash.indexOf('#faq-question-') == 0) {
      const $question = $('.EnglishToursFaq .question' + hash)
      $question.addClass('active')
      $question.find('.arrow').addClass('active')
      $question.siblings('.answer').addClass('active')
    }
  }

  $('.EnglishToursFaq .question').click(function () {
    $(this).toggleClass('active')
    $(this).find('.arrow').toggleClass('active')
    $(this).siblings('.answer').toggleClass('active')
  })

  window.addEventListener('popstate', open_question_from_location_hash) // subscribe for url change
  open_question_from_location_hash() // open question on page load
}

function loadTourRouteUi () {
  $('.FullTour .tour-route p').each(function() {

    let title = $(this).find('strong').first().html()
    if (title) {
      $(this).before("<div class='question'><span class='arrow'></span>" + title + "</div>")

      $(this).find('strong').first().html("")
    }

    $(this).wrap('<div class="answer">')
  })

  $('.FullTour .tour-route .question').click(function () {
    console.log('will handle for ', $(this))
    $(this).toggleClass('active')
    $(this).find('.arrow').toggleClass('active')
    $(this).next('.answer').toggleClass('active')
  })
}

function loadUserVoice () {
  // Include the UserVoice JavaScript SDK (only needed once on a page)
  UserVoice = window.UserVoice || [];
  (function () {
    const uv = document.createElement('script')
    uv.type = 'text/javascript'
    uv.async = true
    uv.src = '//widget.uservoice.com/sT27u5Hge61E8FBgbiw.js'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(uv, s)
  })()

  //
  // UserVoice Javascript SDK developer documentation:
  // https://www.uservoice.com/o/javascript-sdk
  //

  // Set colors
  UserVoice.push(['set', {
    accent_color: '#448dd6',
    trigger_color: 'white',
    trigger_background_color: '#c21396',

    locale: window.locale
  }])

  // Identify the user and pass traits
  // To enable, replace sample data with actual user traits and uncomment the line
  UserVoice.push(['identify', {
    // email:      'john.doe@example.com', // User’s email address
    // name:       'John Doe', // User’s real name
    // created_at: 1364406966, // Unix timestamp for the date the user signed up
    // id:         123, // Optional: Unique id of the user (if set, this should not change)
    // type:       'Owner', // Optional: segment your users by type
    // account: {
    //  id:           123, // Optional: associate multiple users with a single account
    //  name:         'Acme, Co.', // Account name
    //  created_at:   1364406966, // Unix timestamp for the date the account was created
    //  monthly_rate: 9.99, // Decimal; monthly rate of the account
    //  ltv:          1495.00, // Decimal; lifetime value of the account
    //  plan:         'Enhanced' // Plan name for the account
    // }
  }])

  // Add default trigger to the bottom-right corner of the window:
  UserVoice.push(['addTrigger', {
    mode: 'contact',
    trigger_position: 'bottom-right'
  }])

  // Or, use your own custom trigger:
  // UserVoice.push(['addTrigger', '#id', { mode: 'contact' }]);

  // Autoprompt for Satisfaction and SmartVote (only displayed under certain conditions)
  // UserVoice.push(['autoprompt', {}]);
}

function loadVKGroups () {
  $('#vk_groups').each((i, e) => {
    VK.init({
      apiId: 8218334,
      onlyWidgets: true
    })

    VK.Widgets.Group('vk_groups', { mode: 4, wide: 1, height: 600, color1: 'FFFFFF', color2: '000000', color3: '5181B8' }, 214147813)
  })
}

function loadVue () {
  // Custom directives
  Vue.directive('cleave', {
    inserted: (el, binding) => {
        el.cleave = new Cleave(el, binding.value || {})
    },
    update: (el) => {
        const event = new Event('input', {bubbles: true});
        setTimeout(function () {
            el.value = el.cleave.properties.result
            el.dispatchEvent(event)
        }, 100);
    }
  })

  // initialize Vue application
  window.vue_root = new Vue({
    el: '#vue-root',
    components: {
      'ContactsForm': ContactsForm.default
    }
  })
}

function loadGoogleSearch () {
  // google search
  $('.gcse-search').each(() => {
    const cx = '014840101880909292894:b_cxtdsfn_m'
    const gcse = document.createElement('script')
    gcse.type = 'text/javascript'
    gcse.async = true
    gcse.src = (document.location.protocol == 'https:' ? 'https:' : 'http:') +
            '//cse.google.com/cse.js?cx=' + cx
    const s = document.getElementsByTagName('script')[0]
    s.parentNode.insertBefore(gcse, s)
  })
}
