const our_benefits_component = Vue.extend({
  name: 'OurBenefits',
  data: function () {
    return {
      tabs: ['support', 'russian_info', 'payments', 'exp'],
      current_tab: 'support'
    }
  },

  methods: {
    open: function (tab_name, $event) {
      if ($event) { $event.preventDefault() }

      this.current_tab = tab_name
    },

    next: function () {
      const idx = _.indexOf(this.tabs, this.current_tab)
      let next_idx = idx + 1
      if (next_idx > (this.tabs.length - 1)) { next_idx = 0 }

      this.current_tab = this.tabs[next_idx]
    },

    prev: function () {
      const idx = _.indexOf(this.tabs, this.current_tab)
      let next_idx = idx - 1
      if (next_idx < 0) { next_idx = this.tabs.length - 1 }

      this.current_tab = this.tabs[next_idx]
    }

  }
})

Vue.component('our-benefits', our_benefits_component)
