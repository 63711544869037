Vue.component('tours-list', Vue.extend({
  name: 'ToursList',
  template: '#tours-list-tpl',
  props: {
    tours: {
      type: Array,
      required: true
    }
  }
}))
