// TODO:
// Либо таки придумать единый mixin для моих разных проектов
// Либо таки снести нахер этот миксин, так как в этом проекте это нигде и не используется, кроме как %tour - компоненте
window.RemoteFormMixin = {

  data: function () {
    return {
      submit_in_progress: false
    }
  },

  methods: {
    process_template_from_server: function (template) {
      return $(template).html()
    },

    render_new_form: function (template) {
      // remove wrapper script tag
      // var html = this.process_template_from_server(template)

      // interpolate
      const html = this.$interpolate(template)

      // replace with new html
      $(this.$el).html(html)

      // compile
      this.$compile(this.$el)

      this.bind_form_events()

      console.log('render new form finished')
    },

    bind_form_events: function () {
      const success = 'ajax:success'
      const error = 'ajax:error'
      const submit = 'submit'

      const $form = $(this.$el).find('form')

      if ($form.length == 0) return

      $form.off(submit).on(submit, (e) => {
        if (this.submit_in_progress == true) {
          e.preventDefault()
          console.log('submit in progress, so prevented')
          return false
        } else {
          this.submit_in_progress = true
        }
      })

      $form.off(success).on(success, (e, response) => {
        console.log('SUCCESS')
        this.submit_in_progress = false

        this.$emit('hook:remote_form_updater:success')

        if (response.redirect_url) {
          // TODO: NOTICE: DANGER: не ебу как сделать красивее
          const redirect_url = this.$data.redirect_url || response.redirect_url

          window.location.href = redirect_url
        } else {
          this.render_new_form(response)

          $(window).scrollTo($('.page-content-container').first(), { duration: 1000 })
        }
      })

      $form.off(error).on(error, (e, xhr) => {
        this.submit_in_progress = false
        console.log('error')

        this.render_new_form(xhr.responseText)

        $(window).scrollTo($('.page-content-container').first(), { duration: 1000 })
      })
    }
  },

  created: function() {
    this.bind_form_events()
  }

}
